import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import StoryIntro from '../../components/story-intro'

SwiperCore.use([Navigation, Pagination])

const PageHeader = <Header title="Türbedien&shy;modul" />

const TuerbedienmodulPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        allFile(filter: {relativeDirectory: {eq: "stories/tuerbedienmodul"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/tuerbedienmodul/' }, { code: 'en', link: '/en/story/door-control-module/' }]} pageLang="de" pageTitle="Das Türbedienmodul" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Touch-Bedienelemente sind allgegenwärtige Komponenten in einer immer stärker technologisierten Welt.
                            Sie vereinen Ästhetik und Komfort.
                            Gleichzeitig wird ihre Bedienung – ohne den Blick von der Fahrbahn zu lösen – zu einer Herausforderung für den Fahrer eines modernen Automobils.
                            GE-T hat für dieses Problem bereits intelligente Lösungen erforscht und entwickelt.
                        </p>
                    </StoryIntro>
                    <p>
                        Über das haptische Feedback des aus den Volkswagen ID.3 und ID.4 bekannten <Link to="/de/story/multifunktions-touchlenkrad/">Multifunktions-Touchlenkrades</Link> hinaus hat GE-T insbesondere das Türbedienmodul neu gedacht. 
                        Dieses Projekt wurde von den ersten Ideen, über verschiedene Demonstratoren und Prototypen bis zum seriennahen Bedienteil komplett von GE-T realisiert.
                    </p>
                    <p>
                        Zusätzlich zu den durchleuchteten und dreidimensional geformten IML-Kunststoff-Bauteilen setzt GE-T auch hier Touch-Oberflächen mit haptischem Feedback ein. 
                        Insbesondere die aktive Rückmeldung an den Nutzer unterstützt den Einsatz in einem Fahrzeug. Das Erlebnis des Feedbacks ermöglicht – in Kombination mit den in die Oberfläche integrierten Strukturen für das Auffinden des entsprechenden Bedienfeldes – die Blindbedienung durch den Fahrer.
                    </p>
                    <p>
                        Das wegweisende Konzept gewährleistet mit dem zeitgleichen Aktivieren von zwei geometrisch voneinander getrennt liegenden Bedienflächen jederzeit den Schutz vor Fehlbedienungen: 
                        Die aktive Doppelberührung ersetzt die bisher übliche „Pull-to-Close“-Funktion und sorgt damit für die hohe Bediensicherheit der Fensterheber zum Wohle aller Insassen eines modernen Fahrzeuges.
                    </p>
                    <Swiper
                        navigation
                        loop
                        pagination={{ clickable: true }}
                    >
                        {/* eslint-disable-next-line */}
                        {images.allFile.nodes.map((image) => <SwiperSlide key={image.name}><GatsbyImage image={getImage(image)} alt="" /></SwiperSlide>)}
                    </Swiper>
                </section>
            </Container>
        </Layout>
    )
}

export default TuerbedienmodulPage